// PricingSection.jxs
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap
import "./Price.css";
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div className="container pricing-container">
      <h2 className="pricing-title">Flat & Simple Monthly Pricing</h2>
      <p className="pricing-subtitle">
        No hidden costs, no hiring cost, just pay only for the pure work. Save thousands with our Simple and Flat 
        Monthly Pricing. All Plans come with a 7 days money-back guarantee, no questions asked.
      </p>
      {/* Responsive gap utilities for larger screens */}
      <div className="row row-cols-1 row-cols-lg-3 g-4 g-lg-5">
        {/* Card 1 */}
        <div className="col">
          <div className="pricing-card price-margin">
            <div className="card-header">Advance Design</div>
            <h3 className="card-title">Product Design as a Service</h3>
            <p className="card-price">$3799/mo</p>
            <ul className="card-features">
              <li>Dedicated Product Designer</li>
              <li>Multiple Projects</li>
              <li>Real-time Collaboration on Slack / Microsoft Teams</li>
              <li>Daily Updates</li>
              <li>No contracts - Pause or cancel anytime</li>
            </ul>
           <Link to={'https://calendly.com/nida-ideal/call-with-media-dunes?month=2024-11'}> <button className="card-button">Book a Demo-call</button></Link>
          </div>
        </div>
        {/* Card 2 */}
        <div className="col">
          <div className="pricing-card center-card">
            <div className="card-header">Advance Combo</div>
            <h3 className="card-title">Product Design & Frontend Engineering as a Service</h3>
            <p className="card-price">
              $6399/mo <span className="original-price">$7000</span>
            </p>
            <ul className="card-features">
              <li>Dedicated Product Designer</li>
              <li>Dedicated Frontend Engineer (Vue.js, Angular.Js, React.js)</li>
              <li>Multiple Projects</li>
              <li>Real-time Collaboration on Slack / Microsoft Teams</li>
              <li>Daily Updates</li>
              <li>No contracts - Pause or cancel anytime</li>
            </ul>
            <Link to={'https://calendly.com/nida-ideal/call-with-media-dunes?month=2024-11'}>   <button className="card-button">Book a Demo-call</button></Link>
          </div>
        </div>
        {/* Card 3 */}
        <div className="col">
          <div className="pricing-card price-margin">
            <div className="card-header">Advance Frontend</div>
            <h3 className="card-title">Frontend Engineering as a Service</h3>
            <p className="card-price">$3799/mo</p>
            <ul className="card-features">
              <li>Dedicated Frontend Engineer (Vue.js, Angular.Js, React.js)</li>
              <li>Multiple Projects</li>
              <li>Real-time Collaboration on Slack / Microsoft Teams</li>
              <li>Daily Updates</li>
              <li>No contracts - Pause or cancel anytime</li>
            </ul>
            <Link to={'https://calendly.com/nida-ideal/call-with-media-dunes?month=2024-11'}>   <button className="card-button">Book a Demo-call</button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;