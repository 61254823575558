import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../Header/Header.css';
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
    return (
      <header>
        {/* Announcement Bar */}
        <div className="announcement-bar">
          The Ultimate UX Audit Checklist for SaaS Founders is now available.{" "}
          <Link to="/audit" className="highlight-link">
            <b>Learn More</b>
          </Link>
        </div>

        {/* Main Navigation */}
        <nav className="navbar navbar-expand-lg main-header">
          <div className="container">
            {/* Logo */}
            <Link className="navbar-brand" to="/">
              <img
                src="logo.png"
                alt="Media Dunes Logo"
                className="logo"
              />
            </Link>

            {/* Toggler Button for Mobile */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
           
            {/* Navigation Links */}
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/ourwork">
                    How we Deliver
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/price">
                    Pricing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/ourwork">
                    Our Work Library
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/audit">
                    Free UI/UX Audit
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blogs">
                    Blogs
                  </Link>
                </li>
              </ul>
              <Link to="/contactus" className="contact-btn" style={{ textDecoration: "none" }}>
                Contact Us
              </Link>
            </div>
          </div>
        </nav>
      </header>
    );
};

export default Header;
