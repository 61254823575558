import React, { useState } from "react";
import "./Faqs.css";

const FAQ = () => {
  const faqData = [
    {
      question: "Who is this productized service for?",
      answer: "This service is for SaaS founders, businesses, and individuals seeking professional UI/UX design and development services tailored to their needs.",
    },
    {
      question: "What kind of Designs Mediadunes can do?",
      answer: "Mediadunes specializes in modern UI/UX designs, SaaS application interfaces, and mobile-friendly layouts.",
    },
    {
      question: "What kind of Designs Mediadunes can't do?",
      answer: "Mediadunes doesn't handle physical design work, such as printed materials or graphic art not related to SaaS.",
    },
    {
      question: "What does Unlimited UI/UX & Frontend Development mean?",
      answer: "It means you can request as many design revisions and frontend development iterations as needed within the agreed project scope.",
    },
    {
      question: "Does Frontend Development mean APIs Integration too?",
      answer: "No, frontend development focuses on UI interactions and client-side code. API integration would require backend support.",
    },
    {
      question: "Explain more about Cancel and Money Back Guarantee Policy",
      answer: "You can cancel anytime, and if you're not satisfied with our work, you can request a refund as per our policy.",
    },
    {
      question: "You look very cost-effective, why is that?",
      answer: "We streamline our processes and focus on SaaS-specific projects to reduce overhead costs and pass the savings to you.",
    },
    {
      question: "Who owns the ownership of the Designs? Can I resell the work Mediadunes creates for me?",
      answer: "Yes, you own the designs we create for you, and you’re free to use or resell them as you wish.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-title">FAQ</h2>
      <ul className="faq-list">
        {faqData.map((faq, index) => (
          <li key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleAnswer(index)}
            >
              {faq.question}
              <span className="toggle-icon">{openIndex === index ? "-" : "+"}</span>
            </div>
            {openIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
