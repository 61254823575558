import React from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
    <footer className="footer">
        <div className="footer-content">
          <h2>Let's make something amazing together!</h2>
          <div className="footer-buttons">
            <a href="https://calendly.com/nida-ideal/call-with-media-dunes">
              <button className="demo-btn">Book a Demo</button>
            </a>
            <Link to={'/contactus'}><button className="contects-btn">Contact us</button></Link>
          </div>

          <div className="footer-bottom">
            <div className="footer-logo-address">
              <img
                className="footer-logo"
                src="footer-logo.png"
                width="300px"
                height="90px"
                alt=""
              />
              <p>Marasi Drive 51, DAMAC Business Tower 907, Business Bay, Dubai UAE</p>
            </div>

            <div className="footer-links" >
              <div>
                <ul>
                  <li>
                   <Link to={'/ourwork'} style={{textDecoration:'none', color:'white'}}>Our Work</Link> 
                  </li>
                  <li>
                   <Link to={'/price'} style={{textDecoration:'none', color:'white'}}>Pricing </Link>
                  </li>
                  <li>
                   <Link to={'/blogs'} style={{textDecoration:'none', color:'white'}}> Blogs</Link>
                  </li>
                  <li>
                  <Link to={'/career'} style={{textDecoration:'none', color:'white'}}>  Career</Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                   <Link to={'/audit'} style={{textDecoration:'none', color:'white'}}> UI/UX Audit</Link>
                  </li>
                  <li>
                   <Link to={'/uiuxdesign'} style={{textDecoration:'none', color:'white'}}> UI/UX Design</Link>
                  </li>
                  <li>
                   <Link to={'/privacy'} style={{textDecoration:'none', color:'white'}}> Privacy Policy</Link>
                  </li>
                  <li>
                   <Link to={'/termsconditions'} style={{textDecoration:'none', color:'white'}}> Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                   <Link to={'/contactus'} style={{textDecoration:'none', color:'white'}}> Contact Us</Link>
                  </li>
                 
                  <li>
                   <Link to={'ourteam'} style={{textDecoration:'none', color:'white'}}> Our Team</Link>
                  </li>
                 
                  <li>
                   <Link to={'/graphic'} style={{textDecoration:'none', color:'white'}}> UI/UX Audit Checklist</Link>
                  </li>
                  <li>
                   <Link to={'/graphic'} style={{textDecoration:'none', color:'white'}}> FrontEnd Development</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-bottom" style={{ backgroundColor: '', color: 'white', }}>
            <p className="copyright">© Media Dunes, Inc. 2025. We love our users!</p>
            
          </div>
        </div>
        
      </footer>
    </>
  );
};

export default Footer;